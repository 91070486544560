import http from "@/utils/request";
export function getResuList(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/distributorBillList.nd",
      params:data
    });
  }
  // 导出
  export function getResuListFile(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/exportData.nd",
      params:data,
      responseType: 'blob'
    });
  }
  // 删除单行
 
  export function delLine(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/deleteById.nd",
      params:data
    });
  }

  // publishThis发布单挑信息
  
  export function publishThis(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/publish.nd",
      params:data
    });
  }

  // 暂停
    
  export function stopInfoThis(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/stop.nd",
      params:data
    });
  }
  
  // 查看历史 
  export function openHistory(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/viewHistory.nd",
      params:data
    });
  }

  // 批量暂停
  export function batchStop(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/batchStop.nd",
      params:data
    });
  }
  // 批量发布
  export function batchPublish(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/batchPublish.nd",
      params:data
    });
  }

  // 批量删除
  export function batchDeleteById(data) {
    return http({
      method: "get",
      url: "/channelPriceDistributor/batchDeleteById.nd",
      params:data
    });
  }
  // 下载模板
  export function download(data) {
    return http({
      method: "get",
      url: "/upload/distributorPriceProudct.xls",
      data,
      responseType: 'blob'
    });
  }
  // 导入以后查询
  export function importSearch(data) {
    return http({
      method: "get",
      url: "/distributorChannelPriceProductFile/priceProductFileInit.nd",
      params:data
    });
  }
  // 导入提交
  export function importSumbit(data) {
    return http({
      method: "post",
      url: "/distributorChannelPriceProductFile/priceProductFileSubmit.nd",
      data
    });
  }